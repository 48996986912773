<template>
    <div id="app">
    <router-view></router-view>
    </div>
</template>
<script>
import Vue from "vue";
// import axios from "axios";

const bus = new Vue();
Vue.prototype.$bus = bus;
export default {
    data() {
        return {
            message: "hello vue"
        }
    },
  mounted() {
    // axios.get('https://www.lllcz.top/api/test').then((res)=>{
    //   console.log(res);
    // })
  },
  methods: {

    }
}
</script>

<style>
body{
  margin :0;
}

#app {
    min-height: 100%;
    min-width: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}

</style>
