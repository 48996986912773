import Vue from 'vue'
import VueRouter from 'vue-router'
// import axios from "axios";

Vue.use(VueRouter)


// 获取原型对象push函数
// const originalPush = VueRouter.prototype.push
//
// // 获取原型对象replace函数
// const originalReplace = VueRouter.prototype.replace
//
// // 修改原型对象中的push函数
// VueRouter.prototype.push = function push(location){
//     return originalPush.call(this , location).catch(err=>err)
// }
//
// // 修改原型对象中的replace函数
// VueRouter.prototype.replace = function replace(location){
//     return originalReplace.call(this , location).catch(err=>err)
// }


const routes = [
    {
        path: '/',
        redirect:'/login'
    },
    {
        path: '/game',
        name: 'game',
        component: () => import(/* webpackChunkName: "about" */ '../views/tlias/GameView.vue')
    },
    {
        path: '/game',
        name: 'game',
        component: () => import(/* webpackChunkName: "about" */ '../views/tlias/GameView.vue')
    },
    {
        path:'/login',
        name:'login',
        component:()=>import('../views/tlias/LoginView.vue')
    },

    {
        path:'/chat',
        name:'chat',
        component:()=>import('../views/tlias/ChatView.vue')
    },
    {
        path:'/main',
        name:'main',
        component:()=>import('../views/tlias/MainView.vue')
    },
    {
        path:'/history',
        name:'history',
        component:()=>import('../views/tlias/HistoryView.vue')
    },
    {
        path:'/mine',
        name:'mine',
        component:()=>import('../views/tlias/MineView.vue')
    },
    {
        path:'/search',
        name:'search',
        component:()=>import('../views/tlias/SearchView.vue')
    },
    {
        path:'/foot',
        name:'foot',
        component:()=>import('../views/element/FootmenuView.vue')
    }
]
    const router = new VueRouter({
        routes
    })

export default router
