import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import axios from "axios";
import { MessageBox } from 'element-ui';


Vue.config.productionTip = false
Vue.use(ElementUI);
const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem("Version");
if(VUE_APP_VERSION != vers){
    localStorage.clear()
    window.localStorage.setItem("Version", VUE_APP_VERSION);
    location.reload()
}

router.beforeEach((to, from, next) => {
    next();
    document.title = to.meta.title || '蛋炒饭爱学习';
});
// 添加请求拦截器
axios.interceptors.request.use(config => {
    console.log(config.url);
    if (config.url.includes('/login')) {
        // 如果请求路径包含 "/login"，则不执行检查 JWT Token 的操作
        return config;
    }
    const jwt = localStorage.getItem('token');
    if (jwt) {
        config.headers['token'] = jwt;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(response => {
    // 检查是否未登录的条件（示例：根据后端返回的信息）
    const notLoggedIn = response.config.url !== '/login' && response.data.code === 0 && response.data.msg === 'NOT_LOGIN';

    if (notLoggedIn) {
            MessageBox.confirm('使用前需要登录', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                router.push('/login').then(r => console.log(r)); // 跳转到/login页面
            }).catch(() => {
            });
    }
    return response;
}, error => {
    return Promise.reject(error);
});


new Vue({
    el: "#app",
    router: router,
    render: h => h(App)
})